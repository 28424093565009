import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { SessionTimeoutTestIds } from '@layout/session-timeout/components/session-timeout/session-timeout.test-ids';
import { CountdownComponent } from '@layout/session-timeout/components/countdown/countdown.component';
import { ModalModule } from '@design/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RegularButtonComponent } from '@design/button/components/regular-button/regular-button.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  standalone: true,
  selector: 'itc-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CountdownComponent, ModalModule, TranslateModule, RegularButtonComponent],
})
export class SessionTimeoutComponent implements OnInit {
  readonly testIds = SessionTimeoutTestIds;
  private readonly autoLogoutDelayMs = 120 * 1000;

  constructor(
    private dialogRef: DynamicDialogRef,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    timer(this.autoLogoutDelayMs)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.logOut());
  }

  resumeSession(): void {
    this.dialogRef.close(true);
  }

  logOut(): void {
    this.dialogRef.close(false);
  }

  getLogoutDelayInSeconds(): number {
    return this.autoLogoutDelayMs / 1000;
  }
}
