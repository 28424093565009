import { Route } from '@angular/router';

export const paymentManagementRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'payment-assignment',
  },
  {
    path: 'payment-assignment',
    loadComponent: () =>
      import('@pages/payment-management/components/payment-assignment/payment-assignment.component').then(
        component => component.PaymentAssignmentComponent
      ),
  },
  {
    path: 'payment-method-management',
    loadComponent: () =>
      import('@pages/payment-management/components/payment-method-management/payment-method-management.component').then(
        component => component.PaymentMethodManagementComponent
      ),
  },
];
