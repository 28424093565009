import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Country } from '@core/locations/models/country.enum';
import { UserInfoFacade } from '@core/user-info/facades/user-info.facade';

@Directive({
  selector: '[itcCountryAccess]',
})
export class CountryAccessDirective {
  @Input() set itcCountryAccess(countries: Country[] | null) {
    if (!countries) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    if (this.userInfo.isUserFromCountries(countries)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.viewContainer.clear();
  }

  constructor(
    private templateRef: TemplateRef<never>,
    private viewContainer: ViewContainerRef,
    private userInfo: UserInfoFacade
  ) {}
}
