import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from '@design/dialog/services/dialog.service';
import { TutorialsDialogComponent } from '@layout/tutorials/components/tutorials-dialog/tutorials-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from '@design/menu/menu.module';
import { WhatsNewComponent } from '@layout/whats-new/components/whats-new/whats-new.component';
import { TutorialsModule } from '@layout/tutorials/tutorials.module';
import { Subject } from 'rxjs';
import { Permission } from '@core/permissions/models/permission.enum';
import { PermissionsModule } from '@core/permissions/permissions.module';
import { DialogSize } from '@design/dialog/models/dialog-size.enum';

@Component({
  standalone: true,
  selector: 'itc-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, MenuModule, WhatsNewComponent, TutorialsModule, PermissionsModule],
})
export class HelpMenuComponent {
  @ViewChild('menu') menuTemplateRef: TemplateRef<unknown>;

  readonly knowledgeBaseLink = 'https://help.one.kaseya.com/help/Default_CSH.htm#1000';
  readonly statusLink = 'https://status.kaseya.net';
  readonly releaseNotesLink = 'https://help.one.kaseya.com/help/Default_CSH.htm#1011';
  readonly termsOfUseLink = 'https://www.kaseya.com/legal/website-terms-of-use/';
  readonly privacyPolicyLink = 'https://www.kaseya.com/legal/kaseya-privacy-statement/';
  readonly Permission = Permission;

  closeMenu$ = new Subject<void>();

  constructor(private dialogService: DialogService) {}

  openLink(link: string): void {
    window.open(link);
  }

  openVideoTutorialsDialog(): void {
    this.dialogService.openDialog(TutorialsDialogComponent, { width: DialogSize.Lg });
  }
}
