import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfoFacade } from '@core/user-info/facades/user-info.facade';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'itc-impersonate-banner',
  templateUrl: './impersonate-banner.component.html',
  styleUrls: ['./impersonate-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class ImpersonateBannerComponent {
  constructor(
    private userInfoFacade: UserInfoFacade,
    private router: Router
  ) {}

  get userFullName(): string {
    return this.userInfoFacade.userFullName;
  }

  get organizationName(): string {
    return this.userInfoFacade.organizationName;
  }

  logOut(): void {
    this.router.navigateByUrl('logout');
  }
}
