import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { UserInfoFacade } from '@core/user-info/facades/user-info.facade';
import { AuthorizationService } from '@core/authorization/services/authorization.service';

export const provideUserInfoEnvironment = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: loadUserInfoFactory,
      deps: [UserInfoFacade, AuthorizationService],
      multi: true,
    },
  ]);

function loadUserInfoFactory(userInfoFacade: UserInfoFacade, authorizationService: AuthorizationService): () => void {
  return () => {
    if (authorizationService.isAuthorized()) {
      userInfoFacade.loadUserInfo();
    }
  };
}
