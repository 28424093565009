import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { UserInfoFacade } from '@core/user-info/facades/user-info.facade';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private appInsights: ApplicationInsights;

  constructor(
    authorizationService: AuthorizationService,
    private userInfoFacade: UserInfoFacade
  ) {
    if (AppInsightsService.isEnabled) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: AppInsightsService.instrumentationKey,
        },
      });
      this.appInsights.loadAppInsights();
      this.appInsights.addTelemetryInitializer(this.initializeTelemetry);
    }
    authorizationService.onLogin.subscribe(() => {
      this.appInsights.clearAuthenticatedUserContext();
      this.appInsights.setAuthenticatedUserContext(this.userInfoFacade.username, undefined, true);
    });
    authorizationService.onLogout.subscribe(() => {
      this.appInsights.clearAuthenticatedUserContext();
    });
  }

  trackException(exception: Error, properties: Record<string, string> = {}): void {
    this.appInsights.trackException({ exception }, properties);
  }

  startTrackPage(name: string): void {
    this.appInsights.startTrackPage(name);
  }

  stopTrackPage(name: string, properties?: Record<string, string>): void {
    this.appInsights.stopTrackPage(name, undefined, properties);
  }

  static get isEnabled(): boolean {
    return environment.appInsightsInstrumentationKey !== null;
  }

  static get instrumentationKey(): string {
    return environment.appInsightsInstrumentationKey;
  }

  private initializeTelemetry(envelope: ITelemetryItem): boolean | void {
    const telemetryItem = envelope.baseData || {};
    telemetryItem.properties = telemetryItem.properties || {};
    telemetryItem.properties.Environment = environment.envName;
  }
}
