import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Route, Router, UrlTree } from '@angular/router';
import { UserInfoFacade } from '@core/user-info/facades/user-info.facade';

@Injectable()
export class CountryGuard {
  constructor(
    private router: Router,
    private userInfoFacade: UserInfoFacade
  ) {}

  canMatch(route: Route): UrlTree | boolean {
    const accessibleCountries = route.data?.accessibleCountries;
    if (accessibleCountries && !this.userInfoFacade.isUserFromCountries(accessibleCountries)) {
      return this.router.createUrlTree(['access-denied']);
    }

    return true;
  }
}

export const canMatchCountry: CanMatchFn = (route: Route) => {
  return inject(CountryGuard).canMatch(route);
};
